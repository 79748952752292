<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="text-subtitle-1 text-sm-h6">製品登録</span>
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>
      <v-card-text class="px-2 pb-4">
        <v-form v-model="valid" ref="form">
          <v-row no-gutters v-for="(row, i) in formData" :key="i">
            <v-col
              class="px-2 pb-2"
              v-for="col in row"
              :key="col.value"
              :cols="col.cols"
              :sm="col.sm"
            >
              <v-text-field
                v-model="$data[col.value]"
                :type="col.type"
                :rules="
                  col.value == 'productName'
                    ? [
                        required,
                        !products.some((e) => e.id != productUID && e.productName == productName) ||
                          'すでに登録されています',
                      ]
                    : [required]
                "
              >
                <template slot="label">
                  <span class="text-body-2 text-sm-body-1">{{ col.label }}</span>
                  <span class="asterisk">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div class="text-caption text--secondary">
          <p class="ma-0" v-if="updatedAt">更新日：{{ updatedAt }}</p>
          <p class="ma-0" v-if="updaterName">更新者：{{ updaterName }}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="processing"
          :disabled="processing || !valid"
          @click="registProduct()"
        >
          登録
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase, { db } from "../../plugins/firebase";
import moment from "moment";

export default {
  props: {
    products: Array,
    storeItem: Function,
    updateItem: Function,
  },
  data: () => ({
    dialog: false,
    valid: false,
    required: (value) => !!value || "必須入力です",
    productUID: "",
    formData: [
      [{ label: "製品名", value: "productName", cols: 12, sm: 8 }],
      [
        { label: "製品重量", value: "productWeight", type: "number", cols: 5, sm: 5 },
        { label: "製品重量単位", value: "productWeightUnit", cols: 5, sm: 4 },
      ],
      [
        { label: "添加量下限", value: "weightMin", type: "number", cols: 4 },
        { label: "添加量上限", value: "weightMax", type: "number", cols: 4 },
        { label: "重量単位", value: "weightUnit", cols: 4 },
      ],
      [
        { label: "流量下限", value: "flowMin", type: "number", cols: 4 },
        { label: "流量上限", value: "flowMax", type: "number", cols: 4 },
        { label: "流量単位", value: "flowUnit", cols: 4 },
      ],
    ],
    productName: "",
    productWeight: null,
    productWeightUnit: null,
    weightMax: null,
    weightMin: null,
    weightUnit: null,
    flowMax: null,
    flowMin: null,
    flowUnit: null,
    updatedAt: "",
    updaterName: "",
    processing: false,
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {object} product
     */
    async openDialog(product) {
      this.productUID = product?.id ?? "";
      for (const row of this.formData) {
        for (const col of row) this[col.value] = product ? String(product[col.value]) : "";
      }
      this.updatedAt = product ? moment(product.updatedAt.seconds * 1000).format("YYYY/MM/DD") : "";
      this.updaterName = product ? product.updaterName : "";

      this.dialog = true;
      this.$nextTick(() => this.$refs.form.resetValidation());
    },

    /**
     * 入力情報をDB登録
     */
    async registProduct() {
      this.processing = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const isCreate = !this.productUID;

      const registId = this.productUID || db.collection("weightContorolProducts").doc().id;
      const registData = {
        productName: this.productName,
        shopUID: shop.shopUID,
        productWeight: Number(this.productWeight),
        productWeightUnit: this.productWeightUnit,
        weightMax: Number(this.weightMax),
        weightMin: Number(this.weightMin),
        weightUnit: this.weightUnit,
        flowMax: Number(this.flowMax),
        flowMin: Number(this.flowMin),
        flowUnit: this.flowUnit,
        updatedAt: serverTimestamp,
        updaterName: user.name,
      };

      if (isCreate) registData.createdAt = serverTimestamp;

      const isSuccess = await this.storeItem({ isCreate, docId: registId, data: registData });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
.asterisk {
  margin-left: 2px;
  color: red;
  font-weight: 700;
}
</style>
