<template>
  <v-dialog v-model="dialog" persistent width="400" max-width="500">
    <v-card class="pa-2 pa-sm-4">
      <v-card-title class="pa-4">
        <span class="text-subtitle-1 text-sm-h6">以下の{{ target }}を削除します。</span>
      </v-card-title>
      <v-card-text class="px-4 pb-4">
        <p class="mb-0">{{ target }}名：{{ targetName }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          class="white--text"
          color="#b71c1c"
          depressed
          :loading="processing"
          :disabled="processing"
          @click="deleteItem()"
        >
          削除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    target: String,
    removeItem: Function,
  },
  data: () => ({
    dialog: false,
    docId: "",
    targetName: "",
    processing: false,
  }),
  methods: {
    /**
     * ダイアログを開く
     */
    openDialog(selectItem, targetName) {
      this.docId = selectItem.id;
      this.targetName = targetName;
      this.dialog = true;
    },

    /**
     * 情報削除
     */
    async deleteItem() {
      this.processing = true;

      await this.removeItem(this.docId);

      this.processing = false;
      this.dialog = false;
    },
  },
};
</script>
