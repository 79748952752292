import { render, staticRenderFns } from "./CheckResultDetail.vue?vue&type=template&id=63554d08&scoped=true&"
import script from "./CheckResultDetail.vue?vue&type=script&lang=js&"
export * from "./CheckResultDetail.vue?vue&type=script&lang=js&"
import style0 from "./CheckResultDetail.vue?vue&type=style&index=0&id=63554d08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63554d08",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VDataTable,VSpacer})
