<template>
  <div>
    <v-overlay :value="menu"></v-overlay>
    <v-menu
      v-model="menu"
      transition="slide-x-reverse-transition"
      :close-on-click="false"
      :close-on-content-click="false"
      :min-width="$vuetify.breakpoint.xs ? 350 : 500"
      @input="openMenu()"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="white"
          v-bind="attrs"
          v-on="on"
          color="primary"
          icon
          elevation="2"
          :large="$vuetify.breakpoint.smAndUp"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card class="pa-2 pa-sm-4">
        <v-card-title class="pa-4 text-subtitle-1 text-sm-h6">
          <span class="mr-5">製品一覧</span>
          <v-chip small color="secondary">
            <span class="py-1 text-caption primary--text">{{ lineName }}</span>
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="menu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-3 pb-4">
          <v-text-field
            :class="$vuetify.breakpoint.xs ? 'input--xs text-body-2' : ''"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="製品名で検索"
            clearable
            hide-details
            dense
            style="width: 300px"
          />
        </v-card-text>

        <v-data-table
          ref="table"
          :headers="headers"
          :items="selctedItems"
          :items-per-page="-1"
          :search="search"
          sort-by="productName"
          no-data-text="製品がありません"
          no-results-text="製品が見つかりません"
          fixed-header
          hide-default-footer
          disable-sort
          :mobile-breakpoint="null"
          dense
          height="400"
          @click:row="selectRow"
        >
          <template v-slot:[`header.weightCheck`]>
            <span>重量点検</span>
            <v-simple-checkbox
              class="pb-2"
              :value="selctedItems.every((e) => e.weight)"
              :indeterminate="
                !selctedItems.every((e) => e.weight) && selctedItems.some((e) => e.weight)
              "
              color="primary"
              hide-details
              :ripple="false"
              @input="selectAll('weight')"
            />
          </template>
          <template v-slot:[`header.flowCheck`]>
            <span>流量点検</span>
            <v-simple-checkbox
              class="pb-2"
              :value="selctedItems.every((e) => e.flow)"
              :indeterminate="
                !selctedItems.every((e) => e.flow) && selctedItems.some((e) => e.flow)
              "
              color="primary"
              hide-details
              :ripple="false"
              @input="selectAll('flow')"
            />
          </template>
          <template v-slot:[`item.weightCheck`]="{ item }">
            <v-simple-checkbox v-model="item.weight" color="primary" :ripple="false" />
          </template>
          <template v-slot:[`item.flowCheck`]="{ item }">
            <v-simple-checkbox v-model="item.flow" color="primary" :ripple="false" />
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="!selctedItems.some((e) => e.weight || e.flow)"
            @click="addItem()"
          >
            リストに追加
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    lineName: String,
    items: Array,
  },
  data: () => ({
    menu: false,
    search: "",
    selctedItems: [],
    headers: [
      { text: "製品名", value: "productName" },
      { text: "重量点検", value: "weightCheck", align: "center", width: 80 },
      { text: "流量点検", value: "flowCheck", align: "center", width: 80 },
    ],
  }),
  methods: {
    /**
     * メニューが開かれた際に、配列の初期化
     */
    openMenu() {
      this.selctedItems = this.items.map((item) => ({
        ...item,
        weight: false,
        flow: false,
      }));
    },

    /**
     * ヘッダーチェック
     */
    selectAll(type) {
      const selectable = this.$refs.table.selectableItems;
      const value = !selectable.every((e) => e[type]);
      for (const item of this.selctedItems)
        if (selectable.some((e) => e.id == item.id)) item[type] = value;
    },

    /**
     * 行クリック
     */
    selectRow(row) {
      const item = this.selctedItems.find((e) => row.id == e.id);
      const value = !item.weight || !item.flow;
      item.weight = value;
      item.flow = value;
    },

    /**
     * リストに追加
     */
    addItem() {
      this.$emit("select", this.selctedItems);
      this.menu = false;
    },
  },
};
</script>

<style scoped>
::v-deep .v-input--selection-controls__input {
  margin: 0 !important;
}

::v-deep .v-data-table__wrapper table thead tr th,
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 12px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 12px !important;
}
</style>
