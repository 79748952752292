<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4">
        <span class="text-subtitle-1 text-sm-h6">点検結果詳細</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <!-- 詳細内容 -->
      <CheckResultDetail :result="item">
        <ButtonCreatePDF title="重量管理" subTitle="点検結果詳細" icon="mdi-scale">
          <CheckResultDetail :result="item" />
          <div class="py-2">
            <p class="mb-1 pl-4 text-caption text-sm-body-2">特記事項</p>
            <ListComments :comments="filteredComments" :relationCheckResult="{}" :readOnly="true" />
          </div>
        </ButtonCreatePDF>
      </CheckResultDetail>

      <!-- 特記事項リスト -->
      <p class="mb-1 pl-4 text-caption text-sm-body-2">特記事項</p>
      <ListComments
        ref="listComments"
        :date="formatDate(item.registeredAt, 'YYYY/MM/DD')"
        :comments="filteredComments"
        :relationCheckResult="{
          uid: item.id,
          title: item.productName,
          isNormal: item.isNormalForReport,
          sentAt: formatDate(item.sentAt, 'MM/DD HH:mm'),
        }"
        serviceName="重量"
        :height="300"
        @update="$emit('update')"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import CheckResultDetail from "../organisms/CheckResultDetail";

export default {
  props: {
    comments: Array,
  },
  components: {
    CheckResultDetail,
  },
  mixins: [calcDate],
  data: () => ({
    dialog: false,
    item: {},
  }),
  computed: {
    filteredComments() {
      return this.comments.filter(
        (e) => e.relationCheckResult && e.relationCheckResult.uid == this.item.id
      );
    },
  },
  methods: {
    /**
     * ダイアログを開く
     * @param {Object} selectedItem 選択した点検結果
     */
    openDialog(selectedItem) {
      this.item = selectedItem;
      this.dialog = true;
      this.$nextTick(() => this.$refs.listComments.initData());
    },
  },
};
</script>
