var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800"},on:{"input":function($event){return _vm.openDialog()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-2 pa-sm-4",attrs:{"color":"#f5f5f5"}},[_c('v-card-title',{staticClass:"pa-4 d-flex align-baseline"},[_c('span',{staticClass:"text-subtitle-1 text-sm-h6"},[_vm._v(" "+_vm._s(_vm.lineUID == "" ? "ライン登録" : "ライン編集")+" ")]),_c('span',{staticClass:"px-4 red--text text-caption"},[_vm._v("*必須項目")])]),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"pa-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
              _vm.required,
              !_vm.lines.some(function (e) { return e.id != _vm.lineUID && e.lineName == _vm.lineName; }) ||
                'すでに登録されています' ],"dense":""},model:{value:(_vm.lineName),callback:function ($$v) {_vm.lineName=$$v},expression:"lineName"}},[_c('template',{slot:"label"},[_vm._v(" ライン名 "),_c('span',{staticClass:"font-weight-bold red--text"},[_vm._v("*")])])],2)],1)],1),_c('v-card',{staticClass:"pa-2 mb-4",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.editedItems,"items-per-page":-1,"no-data-text":"データがありません","fixed-header":"","hide-default-footer":"","disable-sort":"","height":"300","mobile-breakpoint":null,"dense":""},scopedSlots:_vm._u([{key:"header.name",fn:function(){return [_vm._v(" 機械名 "),_c('span',{staticClass:"font-weight-bold red--text"},[_vm._v("*")])]},proxy:true},{key:"header.action",fn:function(ref){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.addItem()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"200px"}},[_c('v-text-field',{staticClass:"text-body-2 pa-0",attrs:{"single-line":"","hide-details":"","placeholder":"必須入力","rules":[_vm.required]},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)]}},{key:"item.checkTime",fn:function(ref){
            var item = ref.item;
return [_c('v-btn-toggle',{attrs:{"mandatory":"","dense":"","color":"primary"},model:{value:(item.checkTime),callback:function ($$v) {_vm.$set(item, "checkTime", $$v)},expression:"item.checkTime"}},_vm._l(([1, 2, 3, 4, 5]),function(num){return _c('v-btn',{key:num,attrs:{"value":num,"small":""}},[_vm._v(" "+_vm._s(num)+" ")])}),1)]}},{key:"item.action",fn:function(ref){
            var index = ref.index;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editedItems.splice(index, 1)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)})],1)],1),_c('p',{staticClass:"ml-4 mb-1 text-body-2"},[_vm._v("備考")]),_c('v-card',{staticClass:"d-flex",attrs:{"outlined":""}},[_c('v-textarea',{staticClass:"text-body-2",attrs:{"placeholder":"テキスト入力","solo":"","flat":"","hide-details":"","no-resize":"","height":"100"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('v-sheet',{staticClass:"d-flex justify-center align-center rounded",attrs:{"width":"100","height":"100"}},[_c('InputImg',{attrs:{"text":_vm.lineName,"url":_vm.imgFileURL,"compressedFile":_vm.compressedFile,"width":"80"},on:{"update:url":function($event){_vm.imgFileURL=$event},"update:compressedFile":function($event){_vm.compressedFile=$event},"update:compressed-file":function($event){_vm.compressedFile=$event}}})],1)],1),_c('v-card-actions',{staticClass:"pt-4"},[_c('div',{staticClass:"d-flex flex-column text-caption text--secondary"},[(_vm.errorMessage)?_c('span',{staticClass:"mt-n7 mb-2 red--text text-body-2"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.updatedAt)?_c('span',[_vm._v("更新日："+_vm._s(_vm.updatedAt))]):_vm._e(),(_vm.updaterName)?_c('span',[_vm._v("更新者："+_vm._s(_vm.updaterName))]):_vm._e()]),_c('v-spacer'),_c('v-btn',{staticClass:"text--secondary",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("閉じる")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","loading":_vm.processing,"disabled":_vm.processing || !_vm.valid},on:{"click":function($event){return _vm.registLine()}}},[_vm._v(" 登録 ")])],1)],1),_c('DialogSendError',{ref:"sendErrorDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }