<template>
  <v-fade-transition>
    <v-card
      id="card"
      v-if="isShow"
      width="264"
      elevation="10"
      color="grey lighten-3"
      @touchstart="touchstart"
      @touchmove="touchmove"
    >
      <v-row no-gutters class="pa-1">
        <v-col class="text-end" cols="12">
          <v-btn icon @click="closeCard">
            <v-icon color="black" size="20">mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col class="pt-4 pb-2 px-2 text-end" cols="12">
          <span class="text-h3">{{ value == "" ? "0" : value }}</span>
        </v-col>
        <v-col cols="9">
          <v-btn
            class="primary--text"
            v-for="key in keys"
            :key="key"
            depressed
            tile
            color="white"
            @click="clickKey(key)"
          >
            <span class="text-body-1">{{ key }}</span>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn class="primary--text" depressed tile color="white" @click="clickKey('del')">
            <v-icon small>mdi-backspace-outline</v-icon>
          </v-btn>
          <v-btn
            class="primary--text"
            depressed
            tile
            color="white"
            @click="clickEnter()"
            height="150"
          >
            <v-icon small>mdi-keyboard-return</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    isShow: Boolean,
    value: String,
  },
  data: () => ({
    keys: ["7", "8", "9", "4", "5", "6", "1", "2", "3", "0", "00", "."],
    x: 0,
    y: 0,
  }),
  methods: {
    /**
     * テンキーを閉じる
     */
    closeCard() {
      this.$emit("close");
    },

    /**
     * タッチした時に、座標を記録する
     * @param {object} e イベント
     */
    touchstart(e) {
      const t = e.touches[0];
      this.x = t.pageX;
      this.y = t.pageY;
    },

    /**
     * スワイプした時に、テンキーを移動させる
     * @param {object} e イベント
     */
    touchmove(e) {
      e.preventDefault();
      const t = e.touches[0];
      const card = document.getElementById("card");
      card.style.left = card.offsetLeft + (t.pageX - this.x) + "px";
      card.style.top = card.offsetTop + (t.pageY - this.y) + "px";
      this.x = t.pageX;
      this.y = t.pageY;
    },

    /**
     * テンキー入力
     * @param {string} text 入力文字
     */
    clickKey(text) {
      let merge = this.value ? this.value : "";
      if (text == ".") {
        if (this.value.includes(".")) return;
        if (!this.value) merge = "0.";
        if (this.value != "") merge = merge + text;
      }
      if (text == "del") merge = merge.slice(0, -1);
      if (!isNaN(Number(text))) merge = merge + text;
      this.$emit("input", merge);
    },

    /**
     * エンターキー入力
     */
    clickEnter() {
      this.$emit("enter");
    },
  },
};
</script>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  height: 50px;
  padding: 0;
  border: 1px solid #eeeeee !important;
}

.theme--light.v-btn:focus::before,
.theme--light.v-btn:hover::before {
  opacity: 0 !important;
}

.v-card {
  background-color: white !important;
  opacity: 0.9;
  position: absolute;
  left: calc(50% - 120px);
  top: calc(100% - 300px);
  z-index: 10;
  touch-action: manipulation;
}
</style>
