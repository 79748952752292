<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">重量点検票</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">{{ businessDate }}</span>
      </v-col>
      <v-col class="d-flex justify-end align-center" cols="4">
        <MenuProduct
          v-if="tabItems.length > 0"
          :lineName="tabItems[tab].lineName"
          :items="products"
          @select="addToList"
        />
      </v-col>
    </v-row>

    <!-- 点検票 -->
    <v-row class="mb-4" no-gutters :style="{ 'min-height': `${getSheetHeight}px` }">
      <v-col class="pa-4" v-if="tabItems.length == 0">
        <span class="text-body-2 text--secondary">ライン情報がありません</span>
      </v-col>

      <v-col v-else>
        <!-- タブ部分 -->
        <v-tabs v-model="tab" center-active show-arrows @change="focusItem.results = null">
          <v-tab v-for="line in tabItems" :key="line.lineName">
            {{ line.lineName }}
          </v-tab>
        </v-tabs>

        <!-- タブ内容 -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tabItem in tabItems" :key="tabItem.lineName">
            <!-- 備考 -->
            <div
              v-if="tabItem.remarks || tabItem.imgFileURL"
              class="pa-4 d-flex align-center"
              style="min-height: 36px"
            >
              <span class="text-caption text--secondary" style="white-space: pre-wrap">{{
                tabItem.remarks
              }}</span>
              <v-btn
                v-if="tabItem.imgFileURL"
                icon
                @click.stop="
                  $refs.dialogImg.openDialog({
                    text: `ライン名：${tabItem.lineName}\n備考：${tabItem.remarks}`,
                    url: tabItem.imgFileURL,
                  })
                "
              >
                <v-icon>mdi-image-outline</v-icon>
              </v-btn>
            </div>

            <div
              v-if="!tabItem.products.some((e) => !!e.checkCards.length)"
              class="pa-4"
              :class="tabItem.remarks ? 'pt-0' : ''"
            >
              <span class="text-body-2 text--secondary">
                右上の＋ボタンから製品を追加して点検してください
              </span>
            </div>

            <v-card
              class="mb-2"
              v-for="product in tabItem.products"
              :key="product.productName"
              color="#f8f8f8"
            >
              <v-card-title v-if="product.checkCards.length > 0" class="px-4 py-2">
                <span class="mr-4 text-subtitle-2 text-sm-subtitle-1">
                  {{ product.productName }}
                </span>
                <span class="text--secondary text-caption">重量：{{ product.productWeight }}g</span>
              </v-card-title>

              <v-row
                class="px-4 pb-2"
                v-for="(card, cardIndex) in product.checkCards"
                :key="card.type"
                no-gutters
              >
                <!-- 重量点検 -->
                <v-card v-if="card.type == '重量'" width="100%" outlined>
                  <v-card-title class="py-2 px-4 d-flex align-center">
                    <span class="mr-4 text-body-2">重量点検</span>
                    <span class="text-caption">
                      重量範囲：{{ product.weightMin }} ～ {{ product.weightMax
                      }}{{ product.weightUnit }}
                    </span>
                    <v-spacer></v-spacer>
                    <MenuComment :item="card" targetKey="commentTitle" />
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="py-2">
                        <v-btn text @click="product.checkCards.splice(cardIndex, 1)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                          <span class="ml-2">一覧から削除</span>
                        </v-btn>
                      </v-card>
                    </v-menu>
                  </v-card-title>
                  <v-data-table
                    :headers="weightHeaders"
                    :items="card.checkItems"
                    :items-per-page="-1"
                    :loading="loading"
                    loading-text="読込中"
                    no-data-text="データがありません"
                    disable-sort
                    fixed-header
                    hide-default-footer
                    :mobile-breakpoint="null"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        :class="
                          item.isCheck
                            ? getWeightResult(item, product) == 'NG'
                              ? 'red lighten-5'
                              : ''
                            : 'grey lighten-2'
                        "
                      >
                        <td style="min-width: 150px">{{ item.machineName }}</td>
                        <td class="d-flex justify-center align-center">
                          <v-switch
                            class="ma-0 pa-0 pl-1"
                            v-model="item.isCheck"
                            hide-details
                            color="primary"
                            :ripple="false"
                            @change="focusItem.results = null"
                          >
                          </v-switch>
                        </td>
                        <td v-for="(_, i) in tabItem.colCounts" :key="i">
                          <v-text-field
                            v-if="item.weightResults[i] != undefined"
                            v-model="item.weightResults[i]"
                            :suffix="product.weightUnit"
                            :type="isTablet ? 'text' : 'number'"
                            :readonly="isTablet"
                            :disabled="!item.isCheck"
                            :rules="[
                              (value) =>
                                product.weightMin <= Number(value) &&
                                Number(value) <= product.weightMax,
                            ]"
                            hide-details
                            dense
                            @focus="focus(item.weightResults, i)"
                            style="min-width: 80px"
                          />
                        </td>
                        <td
                          class="text-center"
                          :class="
                            getWeightResult(item, product) == 'NG'
                              ? ngClass
                              : getWeightResult(item, product) == '未点検'
                              ? 'text--secondary'
                              : ''
                          "
                        >
                          {{ getWeightResult(item, product) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>

                <!-- 流量点検 -->
                <v-card v-if="card.type == '流量'" width="100%" outlined>
                  <v-card-title class="py-2 px-4 d-flex align-center">
                    <span class="mr-4 text-body-2">流量点検</span>
                    <span class="text-caption">
                      流量範囲：{{ product.flowMin }} ～ {{ product.flowMax }}{{ product.flowUnit }}
                    </span>
                    <v-spacer></v-spacer>
                    <MenuComment :item="card" targetKey="commentTitle" />
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ml-2" v-bind="attrs" v-on="on" icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-card class="py-2">
                        <v-btn text @click="product.checkCards.splice(cardIndex, 1)">
                          <v-icon>mdi-trash-can-outline</v-icon>
                          <span class="ml-2">一覧から削除</span>
                        </v-btn>
                      </v-card>
                    </v-menu>
                  </v-card-title>
                  <v-data-table
                    :headers="flowHeaders"
                    :items="card.checkItems"
                    :items-per-page="-1"
                    :loading="loading"
                    loading-text="読込中"
                    no-data-text="データがありません"
                    disable-sort
                    fixed-header
                    hide-default-footer
                    :mobile-breakpoint="null"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <tr
                        :class="
                          item.isCheck
                            ? getFlowResult(item, product) == 'NG'
                              ? 'red lighten-5'
                              : ''
                            : 'grey lighten-2'
                        "
                      >
                        <td style="min-width: 150px">{{ item.machineName }}</td>
                        <td class="d-flex justify-center align-center">
                          <v-switch
                            class="ma-0 pa-0 pl-1"
                            v-model="item.isCheck"
                            hide-details
                            color="primary"
                            :ripple="false"
                            @change="focusItem.results = null"
                          />
                        </td>
                        <td>
                          <v-text-field
                            v-model="item.flowMin"
                            :suffix="product.flowUnit"
                            :type="isTablet ? 'text' : 'number'"
                            :readonly="isTablet"
                            :disabled="!item.isCheck"
                            :rules="[isValidFlow(item.flowMin, item, product)]"
                            hide-details
                            dense
                            @focus="focus(item, 'flowMin', $event)"
                            style="min-width: 100px"
                          />
                        </td>
                        <td>
                          <v-text-field
                            v-model="item.flowMax"
                            :suffix="product.flowUnit"
                            :type="isTablet ? 'text' : 'number'"
                            :readonly="isTablet"
                            :disabled="!item.isCheck"
                            :rules="[isValidFlow(item.flowMax, item, product)]"
                            hide-details
                            dense
                            @focus="focus(item, 'flowMax')"
                            style="min-width: 100px"
                          />
                        </td>
                        <td>
                          <InputButtonToggle
                            :value.sync="item.flowResult"
                            :required="true"
                            :disabled="!item.isCheck"
                          />
                        </td>
                        <td
                          class="text-center"
                          :class="
                            getFlowResult(item, product) == 'NG'
                              ? ngClass
                              : getFlowResult(item, product) == '未点検'
                              ? 'text--secondary'
                              : ''
                          "
                        >
                          {{ getFlowResult(item, product) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <div class="pt-2 text-center" v-if="isInverse(card.checkItems)">
                    <span class="red--text text-caption">下限≦上限で入力してください</span>
                  </div>
                </v-card>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="businessDate"
        :isMonthly="false"
        serviceName="重量"
        @load="existsComment = $event.length > 0"
      />
      <ButtonSendResult
        :loading="sending"
        :isNormal="true"
        :disabled="sending || isDisabledButton"
        @click="sendCheckResult()"
      />
    </div>

    <!-- テンキー -->
    <ButtonTenKey
      :isShow="focusItem.results != null && isTablet"
      :value="focusItem.results != null ? focusItem.results[focusItem.key] : ''"
      @input="inputTenKey"
      @enter="enterTenKey"
      @close="focusItem.results = null"
    />
    <DialogImg ref="dialogImg" />
    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage
      :dialog="messageDialog"
      :message="message"
      :messageText="messageText"
      @close="messageDialog = false"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import firebase from "../../plugins/firebase";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";
import MenuProduct from "../organisms/MenuProduct";
import ButtonTenKey from "../organisms/ButtonTenKey";

export default {
  components: {
    MenuProduct,
    ButtonTenKey,
  },
  mixins: [calcDate, dbProcess, uploadStorage],
  data: () => ({
    timerID: null,
    isTablet: false,
    loading: false,
    businessDate: "",
    tab: 0,
    products: [],
    tabItems: [],
    flowHeaders: [
      { text: "機械", value: "machineName" },
      { text: "点検実施", value: "isCheck", width: 100, align: "center" },
      { text: "下限", value: "flowMin", width: 150 },
      { text: "上限", value: "flowMax", width: 150 },
      { text: "異常なし", value: "flowResult", width: 116, align: "center" },
      { text: "結果", value: "totalResult", width: 80, align: "center" },
    ],
    focusItem: {
      results: null,
      key: null,
    },
    existsComment: false,
    ngClass: "red--text font-weight-bold",
    message: "",
    messageText: "",
    messageDialog: false,
    sending: false,
  }),
  created: async function () {
    this.$emit("created");
    this.logEvent("app_connect");

    // タブレット端末の判定
    const us = navigator.userAgent.toLowerCase();
    this.isTablet = /android|ipod|ipad|iphone|macintosh/.test(us) && "ontouchend" in document;
    if (this.$vuetify.breakpoint.xs) this.isTablet = false;

    this.businessDate = this.calculateBusinessDate(new Date());
    this.tab = 0;
  },
  activated: async function () {
    await this.loadList();

    const setDate = () => (this.businessDate = this.calculateBusinessDate(new Date()));
    this.timerID = setInterval(setDate, 60000);
  },
  deactivated: function () {
    clearInterval(this.timerID);
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    getSheetHeight() {
      const bp = this.$vuetify.breakpoint;
      const offset = this.existsComment ? 186 : 60;
      const height = bp.height - (bp.xs ? 214 : 204) - offset;
      return height <= 370 ? 370 : height;
    },

    /**
     * 重量点検のヘッダー情報を取得
     * @return {array}
     */
    weightHeaders() {
      const machines = this.tabItems[this.tab].machines;
      const max = machines.reduce((max, current) => Math.max(max, current.checkTime), 1);

      const headers = [
        { text: "機械", value: "machineName" },
        { text: "点検実施", value: "isCheck", width: 100, align: "center" },
      ];
      for (let i = 1; i <= max; i++)
        headers.push({ text: `${i}回目`, value: `weightResult[${i}]`, width: 120 });
      headers.push({ text: "結果", value: "totalResult", align: "center", width: 80 });
      return headers;
    },

    /**
     * 重量点検のNG判定
     * @param {object} item 行データ
     * @param {object} product 製品データ
     * @return {string} 判定結果
     */
    getWeightResult() {
      return function (item, product) {
        if (!item.isCheck) return "未点検";
        if (item.weightResults.some((value) => value == "")) return "";
        const existsNG = item.weightResults.some(
          (value) => Number(value) < product.weightMin || product.weightMax < Number(value)
        );
        return item.weightMin != "" && item.weightMax != "" && !existsNG ? "OK" : "NG";
      };
    },

    /**
     * 流量点検の判定列用NG判定
     * @param {object} item 行データ
     * @param {object} product 製品データ
     * @return {string} 判定結果
     */
    getFlowResult() {
      return function (item, product) {
        if (!item.isCheck) return "未点検";
        if (item.flowMin == "" || item.flowMax == "") return "";
        const min = Number(item.flowMin);
        const max = Number(item.flowMax);
        if (min < product.flowMin || product.flowMax < min) return "NG";
        if (max < product.flowMin || product.flowMax < max) return "NG";
        if (min > max) return "NG";
        if (item.flowResult == "NG") return "NG";
        return "OK";
      };
    },

    /**
     * 流量点検のテキストフィールド用NG判定
     * @param {object} value 結果
     * @param {object} item 行データ
     * @param {object} product 製品データ
     * @return {boolean} 判定結果
     */
    isValidFlow() {
      return function (value, item, product) {
        if (Number(value) < product.flowMin || product.flowMax < Number(value)) return false;
        if (item.flowMin == "" || item.flowMax == "") return true;
        if (Number(item.flowMin) > Number(item.flowMax)) return false;
        return true;
      };
    },

    /**
     * 流量点検の下限>上限の判定
     * @param {array} items テーブルデータ
     * @return {string} 判定結果
     */
    isInverse() {
      return function (items) {
        for (const item of items) {
          if (!item.isCheck) continue;
          if (item.flowMin == "" || item.flowMax == "") continue;
          if (Number(item.flowMin) > Number(item.flowMax)) return true;
        }
        return false;
      };
    },

    /**
     * 送信ボタンの活性判定
     * @return {boolean} 判定結果
     */
    isDisabledButton() {
      if (this.tabItems.length == 0) return true;
      if (this.tabItems[this.tab].products.length == 0) return true;

      // 未点検項目があるかの確認
      return this.tabItems[this.tab].products.some((product) => {
        return product.checkCards.some((card) => {
          if (card.checkItems.every((item) => !item.isCheck)) return true;
          return card.checkItems.some((item) => {
            if (!item.isCheck) return false;
            if (card.type == "重量") return item.weightResults.includes("");
            if (item.flowMin == "" || item.flowMax == "") return true;
            if (Number(item.flowMin) > Number(item.flowMax)) return true;
            return false;
          });
        });
      });
    },
  },
  methods: {
    /**
     * DB読み込み
     */
    async loadList() {
      const shop = this.$store.getters.getShop;

      const lines = await this.getQueryDoc({
        collection: "weightContorolLines",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "lineName", directionStr: "asc" }],
      });
      this.products = await this.getQueryDoc({
        collection: "weightContorolProducts",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "productName", directionStr: "asc" }],
      });

      this.tabItems = lines.map((line) => ({
        ...line,
        products: [],
        colCounts: line.machines.reduce((max, current) => Math.max(max, current.checkTime), 1),
      }));
    },

    /**
     * リストから選択した製品をテーブルに追加
     * @param {array} selectedItems 選択した製品
     */
    async addToList(selectedItems) {
      const tabItem = this.tabItems[this.tab];

      for (const product of selectedItems) {
        if (!product.weight && !product.flow) continue;

        // 製品が追加されているか確認
        const existsProduct = tabItem.products.some((p) => p.id == product.id);
        if (!existsProduct) tabItem.products.push({ ...product, checkCards: [] });
        const targetProduct = tabItem.products.find((p) => p.id == product.id);

        // 重量点検カードの作成
        if (product.weight) {
          const existsCard = targetProduct.checkCards.some((e) => e.type == "重量");
          if (!existsCard)
            targetProduct.checkCards.push({
              type: "重量",
              comment: "",
              commentTitle: "重量点検",
              checkItems: tabItem.machines.map((machine) => ({
                machineName: machine.name,
                weightResults: Array(machine.checkTime).fill(""),
                isCheck: true,
              })),
            });
        }

        // 流量点検カードの作成
        if (product.flow) {
          const existsCard = targetProduct.checkCards.some((e) => e.type == "流量");
          if (!existsCard)
            targetProduct.checkCards.push({
              type: "流量",
              comment: "",
              commentTitle: "流量点検",
              checkItems: tabItem.machines.map((machine) => ({
                machineName: machine.name,
                flowMin: "",
                flowMax: "",
                flowResult: "OK",
                isCheck: true,
              })),
            });
        }
      }
    },

    /**
     * テキストフィールドへフォーカスされた時
     */
    focus(results, key) {
      this.focusItem = {
        results,
        key,
        element: document.activeElement,
      };
    },

    /**
     * テキストフィールドへフォーカスされた時
     * @param {string} value 入力されたボタン
     */
    inputTenKey(value) {
      this.$set(this.focusItem.results, this.focusItem.key, value);
      this.focusItem.element.focus();
    },

    /**
     * テンキーのエンターボタンがタップされた時
     */
    enterTenKey() {
      const table = this.focusItem.element.closest("table");
      const inputs = Array.from(table.querySelectorAll("input[type='text']:not([disabled])"));
      const index = inputs.indexOf(this.focusItem.element);
      if (index == inputs.length - 1) {
        this.focusItem.results = null;
        return;
      }
      inputs[index + 1].focus();
    },

    /**
     * 点検結果送信
     */
    async sendCheckResult() {
      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      this.sending = true;

      const shop = this.$store.getters.getShop;
      const user = this.$store.getters.getUser;
      const sendDate = new Date(this.businessDate + " 00:00:00");
      const registeredAt = firebase.firestore.Timestamp.fromDate(sendDate);
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      // 点検結果をループ
      let isSentComment = false;
      const ngItems = [];
      const sentIds = [];
      const writeItems = [];
      for (const product of this.tabItems[this.tab].products) {
        for (const card of product.checkCards) {
          // 全て未点検の場合は送信しない
          if (card.checkItems.every((e) => !e.isCheck)) continue;

          const resultId = this.createDocId("weightCheckResults");
          const sendData = {
            shopUID: shop.shopUID,
            userUID: user.userUID,
            lineName: this.tabItems[this.tab].lineName,
            productName: product.productName,
            type: card.type,
            max: card.type == "重量" ? product.weightMax : product.flowMax,
            min: card.type == "重量" ? product.weightMin : product.flowMin,
            unit: card.type == "重量" ? product.weightUnit : product.flowUnit,
            checkItems: [],
            productWeight: product.productWeight,
            productWeightUnit: product.productWeightUnit,
            isNormalForReport: true,
            registeredAt,
            sentAt: serverTimestamp,
            sender: user.name,
            confirmedAt: "",
            confirmerName: "",
            approvedAt: "",
            approverName: "",
            createdAt: serverTimestamp,
            updatedAt: serverTimestamp,
          };

          // 点検結果の取得
          for (const item of card.checkItems) {
            const checkItem = { machineName: item.machineName };

            if (card.type == "重量") {
              checkItem.weightResults = item.isCheck
                ? item.weightResults.map(Number)
                : item.weightResults.fill("");
              checkItem.totalResult = item.isCheck ? this.getWeightResult(item, product) : "未点検";
            }
            if (card.type == "流量") {
              checkItem.flowMax = item.isCheck ? Number(item.flowMax) : "";
              checkItem.flowMin = item.isCheck ? Number(item.flowMin) : "";
              checkItem.flowResult = item.isCheck ? item.flowResult : "";
              checkItem.totalResult = item.isCheck ? this.getFlowResult(item, product) : "未点検";
            }

            if (checkItem.totalResult == "NG") {
              ngItems.push({
                lineName: sendData.lineName,
                productName: sendData.productName,
                machineName: item.machineName,
                type: card.type,
              });
              sendData.isNormalForReport = false;
            }
            sendData.checkItems.push(checkItem);
          }

          writeItems.push({
            method: "set",
            collection: "weightCheckResults",
            docId: resultId,
            data: sendData,
          });

          // 特記事項がある場合
          if (card.comment || card.imgFileUrl) {
            const commentId = this.createDocId("comments");
            const sendCommentData = {
              name: user.name,
              registrantUID: user.userUID,
              shopUID: shop.shopUID,
              position: user.position,
              content: card.comment ?? "",
              serviceName: "重量",
              relationCheckResult: {
                uid: resultId,
                title: `${sendData.lineName}_${sendData.productName}`,
                isNormal: sendData.isNormalForReport,
                sentAt: moment().format("MM/DD HH:mm"),
              },
              registeredAt,
              createdAt: serverTimestamp,
              updatedAt: serverTimestamp,
            };

            // 画像ありの場合、画像をアップロードする
            if (card.compressedFile) {
              const commentDate = this.businessDate.replace(/\//g, "-");
              const path = `commentsFile/${shop.shopUID}/${commentDate}/${commentId}.jpg`;
              const uploadResult = await this.uploadFile({ path, file: card.compressedFile });

              // 送信失敗の場合
              if (uploadResult.status == "error") {
                const type =
                  uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
                this.$refs.sendErrorDialog.open(type);
                this.sending = false;
                return;
              }

              sendCommentData.imgFileURL = uploadResult.url;
            }

            writeItems.push({
              method: "set",
              collection: "comments",
              docId: commentId,
              data: sendCommentData,
            });
            isSentComment = true;
          }

          sentIds.push(resultId);
        }
      }

      // 送信した点検票が無い場合
      if (sentIds.length == 0) return (this.sending = false);

      // DB登録
      const result = await this.writeTransaction(writeItems);

      // 登録失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        this.logEvent("send_check_list", { message: "点検票の送信に失敗しました。" });
        this.sending = false;
        return;
      }

      // 特記事項リストの更新
      if (isSentComment) await this.$refs.comments.loadComment();

      // 点検票リストの更新
      this.tabItems[this.tab].products = [];

      this.message = "送信しました。";
      this.messageText = "点検数：" + sentIds.length;
      this.messageDialog = true;
      this.logEvent("send_check_list", { message: this.message + this.messageText });

      this.sending = false;

      // 点検結果にNGがあった場合、メール送信
      if (ngItems.length > 0) await this.sendNgMail(ngItems);
    },

    /**
     * 点検結果にNGがあった場合、メール送信
     * @param {array} items NG点検結果
     */
    async sendNgMail(items) {
      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;

      // 本文に記載する清掃場所一覧の作成
      let lastProduct = "";
      let listText = "";
      for (const item of items) {
        if (lastProduct != item.productName)
          listText = listText + "　製品名：" + item.productName + "\n";

        const type = "　　点検種別：" + (item.type == "重量" ? "重量点検" : "流量点検");
        const lineName = "　ライン名：" + item.lineName;
        const machineName = "　機械名：" + item.machineName + "\n";

        listText = `${listText}${type}${lineName}${machineName}`;
        lastProduct = item.productName;
      }

      let environment = "";
      if (process.env.NODE_ENV == "test") environment = "[DEV]";
      if (process.env.NODE_ENV == "development") environment = "[DEV]";
      if (process.env.NODE_ENV == "staging") environment = "[STG]";

      const functions = await firebase.app().functions("asia-northeast1");
      const sendMail = functions.httpsCallable("sendMail");

      const subject =
        shop.shopUID == this.$route.params.shopUID
          ? `${environment}[ハレコード] 重量点検結果NGのお知らせ`
          : `${environment}[ハレコード][${shop.selectShopName}] 重量点検結果NGのお知らせ`;

      await sendMail({
        domain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        position: user.position,
        shopUID: shop.shopUID,
        subject: subject,
        text:
          "各位\n\n" +
          "重量点検の点検結果にNGがありましたのでお知らせいたします。\n\n" +
          "【店舗名】\n" +
          "　" +
          `${shop.selectShopName}\n\n` +
          "【点検結果】\n" +
          `${listText}\n\n` +
          "以下のURLからログインして内容のご確認をお願いいたします。\n" +
          `${window.location.origin}/${shop.shopUID}/check-summary\n\n` +
          "※このメールは送信専用のため、ご返信いただけません。\n\n\n" +
          "－－－－－－－－－－－－－－－－－－－－－－－－－－－－－－\n" +
          "ハレコードは株式会社ウエノフードテクノの商標です。\n",
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-tabs-bar {
  height: 38px;
  background-color: transparent !important;
  border-bottom: solid 1px #0000001f !important;
}

::v-deep .v-tabs-items {
  background-color: transparent !important;
}

::v-deep .v-window {
  overflow: initial;
}

.theme--light.v-tabs .v-tab--active:focus::before {
  opacity: 0 !important;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0 !important;
}

.sheet-tab {
  overflow-y: auto;
  white-space: pre-wrap;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}

::v-deep .v-text-field__slot input {
  text-align: right;
}

::v-deep .v-text-field__suffix {
  white-space: nowrap;
  font-size: 12px;
  min-width: 30px;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

::v-deep .v-switch-label {
  color: black !important;
  font-weight: initial !important;
}

::v-deep
  .v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset)
  .v-input--switch__thumb {
  color: #fff !important;
}

::v-deep .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
  color: #fff !important;
}
</style>
