<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters :style="{ height: $vuetify.breakpoint.xs ? '76px' : '60px' }">
      <v-col cols="12" sm="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">点検結果一覧</span>
      </v-col>
      <v-col cols="9" sm="5" align-self="center">
        <DatePicker :date.sync="date" @pick="updateDate()" />
      </v-col>
      <v-col class="text-end" cols="3" align-self="center">
        <DialogTotalResult :items="items" :selectedDate="date" />
        <DialogExportResult
          serviceName="重量"
          :properties="exportProperties"
          :selectedDate="date"
        />
      </v-col>
    </v-row>

    <!-- 点検結果一覧 -->
    <v-row class="mb-4" no-gutters>
      <v-col>
        <TableSummary
          :selected.sync="selected"
          :items="items"
          :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
          :filterHeader="filterHeader"
          :comments="comments"
          :loading="loading"
          sortBy="sentAt"
          :sortDesc="true"
          @clickRow="$refs.checkResultDialog.openDialog($event)"
        />
      </v-col>
    </v-row>

    <!-- 特記事項・承認ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="date"
        :isMonthly="date.length == 7"
        serviceName="重量"
        @load="comments = $event"
      />
      <ButtonApproveResult
        :items="selected"
        :disabled="selected.length == 0"
        collection="weightCheckResults"
        @reload="loadList()"
      />
    </div>

    <!-- 結果詳細ダイアログ -->
    <DialogCheckResult
      ref="checkResultDialog"
      :comments="comments"
      @update="$refs.comments.loadComment()"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DialogTotalResult from "../organisms/DialogTotalResult";
import DialogCheckResult from "../organisms/DialogCheckResult";

export default {
  components: {
    DialogTotalResult,
    DialogCheckResult,
  },
  mixins: [calcDate, dbProcess],
  data: () => ({
    loading: false,
    date: "",
    selected: [],
    items: [],
    headers: [
      { text: "製品名", value: "productName", sortable: false, width: 150, class: "responsive" },
      { text: "ライン名", value: "lineName", sortable: false, width: 150, class: "responsive" },
      { text: "点検種別", value: "type", width: 82 },
      { text: "点検結果", value: "isNormalForReport", width: 82 },
      { text: "送信日時", value: "sentAt", width: 100 },
      { text: "確認日時", value: "confirmedAt", width: 100 },
      { text: "承認日時", value: "approvedAt", width: 112 },
    ],
    xsHeaders: [
      { text: "製品名", value: "productName", sortable: false, width: 150, class: "responsive" },
      { text: "ライン名", value: "lineName", sortable: false, width: 150, class: "responsive" },
      { text: "種別", value: "type", width: 58 },
      { text: "結果", value: "isNormalForReport", width: 58 },
      { text: "送信", value: "sentAt", width: 44 },
      { text: "確認", value: "confirmedAt", align: "center", width: 34 },
      { text: "承認", value: "approvedAt", align: "center", width: 46 },
    ],
    filterHeader: { productName: "", lineName: "" },
    comments: [],
    exportProperties: [
      {
        sheetName: "重量点検結果",
        collection: "weightCheckResults",
        resultKey: "checkItems",
        resultColumns: [
          { header: "機械名", key: "machineName" },
          { header: "重量点検結果", key: "weightResults" },
          { header: "流量上限結果", key: "flowMax" },
          { header: "流量下限結果", key: "flowMin" },
          { header: "流量点検結果", key: "flowResult" },
          { header: "点検結果", key: "totalResult" },
        ],
        columns: [
          { header: "ライン名", key: "lineName" },
          { header: "製品名", key: "productName" },
          { header: "点検種別", key: "type" },
          { header: "上限", key: "max" },
          { header: "下限", key: "min" },
          { header: "単位", key: "unit" },
          { header: "製品重量", key: "productWeight" },
          { header: "製品重量単位", key: "productWeightUnit" },
          { header: "点検結果", key: "checkItems" },
          { header: "送信日時", key: "sentAt" },
          { header: "送信者", key: "sender" },
          { header: "確認日時", key: "confirmedAt" },
          { header: "確認者", key: "confirmerName" },
          { header: "承認日時", key: "approvedAt" },
          { header: "承認者", key: "approverName" },
        ],
      },
    ],
  }),
  created: async function () {
    this.$emit("created");
    const queryDate = this.$route.query.date;
    this.date = queryDate ? queryDate : this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    await this.loadList();
  },
  methods: {
    /**
     * DBから点検結果一覧を取得
     */
    async loadList() {
      this.loading = true;
      const shop = this.$store.getters.getShop;
      const isMonthly = this.date.length == 7;

      const startAt = isMonthly
        ? new Date(`${this.date}/01 00:00:00`)
        : new Date(`${this.date} 00:00:00`);
      const endAt = isMonthly
        ? new Date(moment(startAt).add(1, "month").add(-1, "seconds"))
        : new Date(`${this.date} 23:59:59`);

      this.items = await this.getQueryDoc({
        collection: "weightCheckResults",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
        order: [{ fieldPath: "registeredAt", directionStr: "asc" }],
        startAt,
        endAt,
      });

      // 送信日時で降順にソート
      this.items.sort((a, b) => {
        if (new Date(a.sentAt.seconds * 1000) > new Date(b.sentAt.seconds * 1000)) return 1;
        if (new Date(a.sentAt.seconds * 1000) < new Date(b.sentAt.seconds * 1000)) return -1;
        return 0;
      });

      this.selected = [];
      this.loading = false;
    },

    /**
     * 日付変更があったら点検結果再読み込み
     */
    async updateDate() {
      await this.loadList();
      this.$nextTick(() => this.$refs.comments.loadComment());
    },
  },
};
</script>
