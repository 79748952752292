var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-2 pa-sm-4",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-4",staticStyle:{"height":"60px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align-self":"center"}},[_c('span',{staticClass:"mr-5 text-subtitle-1 text-sm-h6"},[_vm._v("重量点検票")]),_c('span',{staticClass:"text-caption text-sm-subtitle-1 text--secondary"},[_vm._v(_vm._s(_vm.businessDate))])]),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"4"}},[(_vm.tabItems.length > 0)?_c('MenuProduct',{attrs:{"lineName":_vm.tabItems[_vm.tab].lineName,"items":_vm.products},on:{"select":_vm.addToList}}):_vm._e()],1)],1),_c('v-row',{staticClass:"mb-4",style:({ 'min-height': (_vm.getSheetHeight + "px") }),attrs:{"no-gutters":""}},[(_vm.tabItems.length == 0)?_c('v-col',{staticClass:"pa-4"},[_c('span',{staticClass:"text-body-2 text--secondary"},[_vm._v("ライン情報がありません")])]):_c('v-col',[_c('v-tabs',{attrs:{"center-active":"","show-arrows":""},on:{"change":function($event){_vm.focusItem.results = null}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(line){return _c('v-tab',{key:line.lineName},[_vm._v(" "+_vm._s(line.lineName)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabItems),function(tabItem){return _c('v-tab-item',{key:tabItem.lineName},[(tabItem.remarks || tabItem.imgFileURL)?_c('div',{staticClass:"pa-4 d-flex align-center",staticStyle:{"min-height":"36px"}},[_c('span',{staticClass:"text-caption text--secondary",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(tabItem.remarks))]),(tabItem.imgFileURL)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.dialogImg.openDialog({
                  text: ("ライン名：" + (tabItem.lineName) + "\n備考：" + (tabItem.remarks)),
                  url: tabItem.imgFileURL,
                })}}},[_c('v-icon',[_vm._v("mdi-image-outline")])],1):_vm._e()],1):_vm._e(),(!tabItem.products.some(function (e) { return !!e.checkCards.length; }))?_c('div',{staticClass:"pa-4",class:tabItem.remarks ? 'pt-0' : ''},[_c('span',{staticClass:"text-body-2 text--secondary"},[_vm._v(" 右上の＋ボタンから製品を追加して点検してください ")])]):_vm._e(),_vm._l((tabItem.products),function(product){return _c('v-card',{key:product.productName,staticClass:"mb-2",attrs:{"color":"#f8f8f8"}},[(product.checkCards.length > 0)?_c('v-card-title',{staticClass:"px-4 py-2"},[_c('span',{staticClass:"mr-4 text-subtitle-2 text-sm-subtitle-1"},[_vm._v(" "+_vm._s(product.productName)+" ")]),_c('span',{staticClass:"text--secondary text-caption"},[_vm._v("重量："+_vm._s(product.productWeight)+"g")])]):_vm._e(),_vm._l((product.checkCards),function(card,cardIndex){return _c('v-row',{key:card.type,staticClass:"px-4 pb-2",attrs:{"no-gutters":""}},[(card.type == '重量')?_c('v-card',{attrs:{"width":"100%","outlined":""}},[_c('v-card-title',{staticClass:"py-2 px-4 d-flex align-center"},[_c('span',{staticClass:"mr-4 text-body-2"},[_vm._v("重量点検")]),_c('span',{staticClass:"text-caption"},[_vm._v(" 重量範囲："+_vm._s(product.weightMin)+" ～ "+_vm._s(product.weightMax)+_vm._s(product.weightUnit)+" ")]),_c('v-spacer'),_c('MenuComment',{attrs:{"item":card,"targetKey":"commentTitle"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-card',{staticClass:"py-2"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return product.checkCards.splice(cardIndex, 1)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("一覧から削除")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.weightHeaders,"items":card.checkItems,"items-per-page":-1,"loading":_vm.loading,"loading-text":"読込中","no-data-text":"データがありません","disable-sort":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('tr',{class:item.isCheck
                          ? _vm.getWeightResult(item, product) == 'NG'
                            ? 'red lighten-5'
                            : ''
                          : 'grey lighten-2'},[_c('td',{staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(item.machineName))]),_c('td',{staticClass:"d-flex justify-center align-center"},[_c('v-switch',{staticClass:"ma-0 pa-0 pl-1",attrs:{"hide-details":"","color":"primary","ripple":false},on:{"change":function($event){_vm.focusItem.results = null}},model:{value:(item.isCheck),callback:function ($$v) {_vm.$set(item, "isCheck", $$v)},expression:"item.isCheck"}})],1),_vm._l((tabItem.colCounts),function(_,i){return _c('td',{key:i},[(item.weightResults[i] != undefined)?_c('v-text-field',{staticStyle:{"min-width":"80px"},attrs:{"suffix":product.weightUnit,"type":_vm.isTablet ? 'text' : 'number',"readonly":_vm.isTablet,"disabled":!item.isCheck,"rules":[
                            function (value) { return product.weightMin <= Number(value) &&
                              Number(value) <= product.weightMax; } ],"hide-details":"","dense":""},on:{"focus":function($event){return _vm.focus(item.weightResults, i)}},model:{value:(item.weightResults[i]),callback:function ($$v) {_vm.$set(item.weightResults, i, $$v)},expression:"item.weightResults[i]"}}):_vm._e()],1)}),_c('td',{staticClass:"text-center",class:_vm.getWeightResult(item, product) == 'NG'
                            ? _vm.ngClass
                            : _vm.getWeightResult(item, product) == '未点検'
                            ? 'text--secondary'
                            : ''},[_vm._v(" "+_vm._s(_vm.getWeightResult(item, product))+" ")])],2)]}}],null,true)})],1):_vm._e(),(card.type == '流量')?_c('v-card',{attrs:{"width":"100%","outlined":""}},[_c('v-card-title',{staticClass:"py-2 px-4 d-flex align-center"},[_c('span',{staticClass:"mr-4 text-body-2"},[_vm._v("流量点検")]),_c('span',{staticClass:"text-caption"},[_vm._v(" 流量範囲："+_vm._s(product.flowMin)+" ～ "+_vm._s(product.flowMax)+_vm._s(product.flowUnit)+" ")]),_c('v-spacer'),_c('MenuComment',{attrs:{"item":card,"targetKey":"commentTitle"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-card',{staticClass:"py-2"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return product.checkCards.splice(cardIndex, 1)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")]),_c('span',{staticClass:"ml-2"},[_vm._v("一覧から削除")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.flowHeaders,"items":card.checkItems,"items-per-page":-1,"loading":_vm.loading,"loading-text":"読込中","no-data-text":"データがありません","disable-sort":"","fixed-header":"","hide-default-footer":"","mobile-breakpoint":null,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('tr',{class:item.isCheck
                          ? _vm.getFlowResult(item, product) == 'NG'
                            ? 'red lighten-5'
                            : ''
                          : 'grey lighten-2'},[_c('td',{staticStyle:{"min-width":"150px"}},[_vm._v(_vm._s(item.machineName))]),_c('td',{staticClass:"d-flex justify-center align-center"},[_c('v-switch',{staticClass:"ma-0 pa-0 pl-1",attrs:{"hide-details":"","color":"primary","ripple":false},on:{"change":function($event){_vm.focusItem.results = null}},model:{value:(item.isCheck),callback:function ($$v) {_vm.$set(item, "isCheck", $$v)},expression:"item.isCheck"}})],1),_c('td',[_c('v-text-field',{staticStyle:{"min-width":"100px"},attrs:{"suffix":product.flowUnit,"type":_vm.isTablet ? 'text' : 'number',"readonly":_vm.isTablet,"disabled":!item.isCheck,"rules":[_vm.isValidFlow(item.flowMin, item, product)],"hide-details":"","dense":""},on:{"focus":function($event){return _vm.focus(item, 'flowMin', $event)}},model:{value:(item.flowMin),callback:function ($$v) {_vm.$set(item, "flowMin", $$v)},expression:"item.flowMin"}})],1),_c('td',[_c('v-text-field',{staticStyle:{"min-width":"100px"},attrs:{"suffix":product.flowUnit,"type":_vm.isTablet ? 'text' : 'number',"readonly":_vm.isTablet,"disabled":!item.isCheck,"rules":[_vm.isValidFlow(item.flowMax, item, product)],"hide-details":"","dense":""},on:{"focus":function($event){return _vm.focus(item, 'flowMax')}},model:{value:(item.flowMax),callback:function ($$v) {_vm.$set(item, "flowMax", $$v)},expression:"item.flowMax"}})],1),_c('td',[_c('InputButtonToggle',{attrs:{"value":item.flowResult,"required":true,"disabled":!item.isCheck},on:{"update:value":function($event){return _vm.$set(item, "flowResult", $event)}}})],1),_c('td',{staticClass:"text-center",class:_vm.getFlowResult(item, product) == 'NG'
                            ? _vm.ngClass
                            : _vm.getFlowResult(item, product) == '未点検'
                            ? 'text--secondary'
                            : ''},[_vm._v(" "+_vm._s(_vm.getFlowResult(item, product))+" ")])])]}}],null,true)}),(_vm.isInverse(card.checkItems))?_c('div',{staticClass:"pt-2 text-center"},[_c('span',{staticClass:"red--text text-caption"},[_vm._v("下限≦上限で入力してください")])]):_vm._e()],1):_vm._e()],1)})],2)})],2)}),1)],1)],1),_c('div',{staticClass:"d-sm-flex align-center"},[_c('CardComments',{ref:"comments",attrs:{"date":_vm.businessDate,"isMonthly":false,"serviceName":"重量"},on:{"load":function($event){_vm.existsComment = $event.length > 0}}}),_c('ButtonSendResult',{attrs:{"loading":_vm.sending,"isNormal":true,"disabled":_vm.sending || _vm.isDisabledButton},on:{"click":function($event){return _vm.sendCheckResult()}}})],1),_c('ButtonTenKey',{attrs:{"isShow":_vm.focusItem.results != null && _vm.isTablet,"value":_vm.focusItem.results != null ? _vm.focusItem.results[_vm.focusItem.key] : ''},on:{"input":_vm.inputTenKey,"enter":_vm.enterTenKey,"close":function($event){_vm.focusItem.results = null}}}),_c('DialogImg',{ref:"dialogImg"}),_c('DialogSendError',{ref:"sendErrorDialog"}),_c('DialogMessage',{attrs:{"dialog":_vm.messageDialog,"message":_vm.message,"messageText":_vm.messageText},on:{"close":function($event){_vm.messageDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }