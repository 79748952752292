<template>
  <v-dialog v-model="dialog" persistent @input="openDialog()" max-width="800">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4 d-flex align-baseline">
        <span class="text-subtitle-1 text-sm-h6">
          {{ lineUID == "" ? "ライン登録" : "ライン編集" }}
        </span>
        <span class="px-4 red--text text-caption">*必須項目</span>
      </v-card-title>

      <v-form v-model="valid" ref="form">
        <v-row class="pa-4" no-gutters>
          <v-col cols="10" sm="6">
            <v-text-field
              v-model="lineName"
              :rules="[
                required,
                !lines.some((e) => e.id != lineUID && e.lineName == lineName) ||
                  'すでに登録されています',
              ]"
              dense
            >
              <template slot="label">
                ライン名
                <span class="font-weight-bold red--text">*</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-card class="pa-2 mb-4" outlined>
          <v-data-table
            :headers="headers"
            :items="editedItems"
            :items-per-page="-1"
            no-data-text="データがありません"
            fixed-header
            hide-default-footer
            disable-sort
            height="300"
            :mobile-breakpoint="null"
            dense
          >
            <template v-slot:[`header.name`]>
              機械名
              <span class="font-weight-bold red--text">*</span>
            </template>
            <template v-slot:[`header.action`]="{}">
              <v-btn icon @click="addItem()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <template #[`item.name`]="{ item }">
              <div style="min-width: 200px">
                <v-text-field
                  class="text-body-2 pa-0"
                  v-model="item.name"
                  single-line
                  hide-details
                  placeholder="必須入力"
                  :rules="[required]"
                />
              </div>
            </template>
            <template #[`item.checkTime`]="{ item }">
              <v-btn-toggle v-model="item.checkTime" mandatory dense color="primary">
                <v-btn v-for="num in [1, 2, 3, 4, 5]" :key="num" :value="num" small>
                  {{ num }}
                </v-btn>
              </v-btn-toggle>
            </template>
            <template #[`item.action`]="{ index }">
              <v-btn icon @click="editedItems.splice(index, 1)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-form>

      <!-- 備考 -->
      <p class="ml-4 mb-1 text-body-2">備考</p>
      <v-card class="d-flex" outlined>
        <v-textarea
          class="text-body-2"
          v-model="remarks"
          placeholder="テキスト入力"
          solo
          flat
          hide-details
          no-resize
          height="100"
        />
        <v-sheet class="d-flex justify-center align-center rounded" width="100" height="100">
          <InputImg
            :text="lineName"
            :url.sync="imgFileURL"
            :compressedFile.sync="compressedFile"
            width="80"
          />
        </v-sheet>
      </v-card>

      <v-card-actions class="pt-4">
        <div class="d-flex flex-column text-caption text--secondary">
          <span class="mt-n7 mb-2 red--text text-body-2" v-if="errorMessage">
            {{ errorMessage }}
          </span>
          <span v-if="updatedAt">更新日：{{ updatedAt }}</span>
          <span v-if="updaterName">更新者：{{ updaterName }}</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="text--secondary" text @click="dialog = false">閉じる</v-btn>
        <v-btn
          color="primary"
          depressed
          :loading="processing"
          :disabled="processing || !valid"
          @click="registLine()"
        >
          登録
        </v-btn>
      </v-card-actions>
    </v-card>

    <DialogSendError ref="sendErrorDialog" />
  </v-dialog>
</template>

<script>
import firebase, { db } from "../../plugins/firebase";
import moment from "moment";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";

export default {
  props: {
    lines: Array,
    storeItem: Function,
    updateItem: Function,
  },
  mixins: [uploadStorage],
  data: () => ({
    dialog: false,
    processing: false,
    valid: false,
    required: (value) => !!value || "必須入力です",
    updatedAt: "",
    updaterName: "",
    lineUID: "",
    lineName: "",
    errorMessage: "",
    editedItems: [],
    headers: [
      { text: "機械名", value: "name" },
      { text: "点検回数", value: "checkTime", align: "center", width: 156 },
      { text: "", value: "action", width: 60 },
    ],
    remarks: "",
    imgFileURL: "",
    compressedFile: null,
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {object} selectItem
     */
    openDialog(selectItem) {
      this.errorMessage = "";
      this.dialog = true;
      this.compressedFile = null;

      if (selectItem == undefined) {
        this.$nextTick(() => {
          this.$refs.form.reset();
          this.lineUID = "";
          this.lineName = "";
          this.editedItems = [];
          this.remarks = "";
          this.imgFileURL = "";
          this.updatedAt = "";
          this.updaterName = "";
          this.addItem();
        });
      } else {
        this.lineUID = selectItem.id;
        this.lineName = selectItem.lineName;
        this.editedItems = JSON.parse(JSON.stringify(selectItem.machines));
        this.remarks = selectItem.remarks ?? "";
        this.imgFileURL = selectItem.imgFileURL ?? "";
        this.updatedAt = moment(selectItem.updatedAt.seconds * 1000).format("YYYY/MM/DD");
        this.updaterName = selectItem.updaterName;
      }
    },

    /**
     * 点検箇所行の追加
     */
    addItem() {
      this.editedItems.push({
        name: "",
        checkTime: 5,
      });
    },

    /**
     * 入力情報をDB登録
     */
    async registLine() {
      // 空白項目がないか確認
      const isEmpty = this.editedItems.some((e) => e.name == "" || e.checkTime == "");
      if (isEmpty) {
        this.errorMessage = "未入力項目があります";
        return;
      }

      this.processing = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const isCreate = !this.lineUID;

      // 登録用データ作成
      const registId = isCreate ? db.collection("weightContorolLines").doc().id : this.lineUID;
      const registData = {
        shopUID: shop.shopUID,
        lineName: this.lineName,
        machines: this.editedItems.map((item) => ({
          name: item.name,
          checkTime: Number(item.checkTime),
        })),
        remarks: this.remarks,
        imgFileURL: this.imgFileURL,
        updatedAt: serverTimestamp,
        updaterName: user.name,
      };

      // 備考画像の保存
      if (this.compressedFile) {
        const path = `weightContorolLineFile/${shop.shopUID}/${registId}/remarks.jpg`;
        const uploadResult = await this.uploadFile({ path, file: this.compressedFile });

        // 送信失敗の場合
        if (uploadResult.status == "error") {
          const type =
            uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
          this.$refs.sendErrorDialog.open(type);
          this.processing = false;
          return;
        }

        registData.imgFileURL = uploadResult.url;
      }

      if (isCreate) registData.createdAt = serverTimestamp;

      const isSuccess = await this.storeItem({ isCreate, docId: registId, data: registData });

      this.processing = false;
      if (isSuccess) this.dialog = false;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 48px !important;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
}

::v-deep .v-data-table__wrapper table tbody tr:last-child td {
  border-bottom: solid 1px #0000001f;
}

::v-deep .v-input input::placeholder {
  color: red !important;
  opacity: 0.5;
}

.v-btn:not(.v-btn--round).v-size--small {
  height: 28px;
  min-width: 28px;
}

.v-textarea ::v-deep .v-input__control .v-input__slot {
  padding: 0 16px !important;
}
</style>
