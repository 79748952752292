<template>
  <v-dialog v-model="dialog" max-width="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="white"
        v-bind="attrs"
        v-on="on"
        color="primary"
        icon
        elevation="2"
        :large="$vuetify.breakpoint.smAndUp"
      >
        <v-icon>mdi-table-eye</v-icon>
      </v-btn>
    </template>
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="px-2 py-4 d-flex align-baseline">
        <span class="mr-3 mr-sm-5 text-subtitle-1 text-sm-h6">【製品別】点検状況確認</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">{{ selectedDate }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text class="pa-4" v-if="totalResults.length == 0" style="height: 50vh">
        <span class="text-body-2 text--secondary">データがありません</span>
      </v-card-text>

      <!-- タブ部分 -->
      <v-tabs v-else v-model="tab" center-active show-arrows>
        <v-tab v-for="product in totalResults" :key="product.productName">
          {{ product.productName }}
        </v-tab>
      </v-tabs>

      <!-- タブ内容 -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="product in totalResults" :key="product.productName">
          <v-card outlined height="70vh" style="background-color: #f8f8f8; overflow-y: auto">
            <v-card-title class="pa-4">
              <span class="text--secondary text-caption">重量：{{ product.productWeight }}g</span>
            </v-card-title>

            <v-row
              class="px-4 pb-2"
              v-for="(result, index) in product.checkResults"
              :key="index"
              no-gutters
            >
              <v-card outlined width="100%">
                <v-card-title class="pa-4">
                  <span class="mr-4 text-body-2 text-subtitle-2">
                    {{ result.type == "重量" ? "重量点検" : "流量点検" }}
                  </span>
                  <div class="text-caption">
                    <span class="mr-4">ライン名：{{ result.lineName }}</span>
                    <span>{{ result.type }}範囲</span>
                    <span class="mr-4">
                      {{ result.min }} ～ {{ result.max }}{{ result.unit }}
                    </span>
                    <span class="mr-4">
                      送信日時：{{ formatDate(result.sentAt, "YYYY/MM/DD HH:mm") }}
                    </span>
                    <span>送信者：{{ result.sender }}</span>
                  </div>
                </v-card-title>
                <v-data-table
                  :headers="headers(result)"
                  :items="result.checkItems"
                  :items-per-page="-1"
                  disable-sort
                  fixed-header
                  hide-default-footer
                  :mobile-breakpoint="null"
                  dense
                >
                  <template #[`header.flowMin`]="{ header }">
                    <span>{{ header.text }}({{ result.unit }})</span>
                  </template>
                  <template #[`header.flowMax`]="{ header }">
                    <span>{{ header.text }}({{ result.unit }})</span>
                  </template>
                  <template #[`item.machineName`]="{ item }">
                    <div style="min-width: 100px">{{ item.machineName }}</div>
                  </template>
                  <template #[`item.weightResult[${i}]`]="{ item }" v-for="i in 5">
                    <span :key="i">{{
                      item.weightResults[i - 1] ? item.weightResults[i - 1].toFixed(1) : ""
                    }}</span>
                  </template>
                  <template v-slot:[`item.flowMin`]="{ item }">
                    <span
                      :class="item.flowMin < result.min || result.max < item.flowMin ? ngClass : ''"
                    >
                      {{ item.flowMin }}
                    </span>
                  </template>
                  <template v-slot:[`item.flowMax`]="{ item }">
                    <span
                      :class="item.flowMax < result.min || result.max < item.flowMax ? ngClass : ''"
                    >
                      {{ item.flowMax }}
                    </span>
                  </template>
                  <template v-slot:[`item.flowResult`]="{ item }">
                    <span :class="item.flowResult == 'NG' ? ngClass : ''">
                      {{ item.flowResult }}
                    </span>
                  </template>
                  <template v-slot:[`item.totalResult`]="{ item }">
                    <span
                      :class="
                        item.totalResult == 'NG'
                          ? ngClass
                          : item.totalResult == '未点検'
                          ? 'text--secondary'
                          : ''
                      "
                    >
                      {{ item.totalResult }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    items: Array,
    selectedDate: String,
  },
  mixins: [calcDate],
  data: () => ({
    dialog: false,
    tab: 0,
    colMax: 1,
    ngClass: "red--text font-weight-bold",
  }),
  computed: {
    /**
     * カスタムヘッダーを作成
     */
    headers() {
      return (card) => {
        if (card.type == "流量") {
          return [
            { text: "機械", value: "machineName" },
            { text: "下限", value: "flowMin", align: "end", width: 100 },
            { text: "上限", value: "flowMax", align: "end", width: 100 },
            { text: "異常なし", value: "flowResult", sortable: false, width: 100, align: "center" },
            {
              text: "点検結果",
              value: "totalResult",
              sortable: false,
              width: 100,
              align: "center",
            },
          ];
        }

        const colMax = card.checkItems.reduce(
          (max, current) => Math.max(max, current.weightResults.length),
          1
        );
        const weightHeaders = [{ text: "機械", value: "machineName" }];
        for (let i = 1; i <= colMax; i++) {
          weightHeaders.push({
            text: `${i}回目`,
            value: `weightResult[${i}]`,
            align: "end",
            width: 80,
          });
        }

        weightHeaders.push({
          text: "点検結果",
          value: "totalResult",
          sortable: false,
          width: 100,
          align: "center",
        });
        return weightHeaders;
      };
    },

    /**
     * 製品別に点検回数の集計
     * @return {array} 集計結果
     */
    totalResults() {
      const results = [];
      const products = [];

      // 点検結果から製品名一覧を取得
      for (const item of this.items) {
        const existsProduct = products.some((e) => e.productName == item.productName);
        if (!existsProduct) {
          products.push({
            productName: item.productName,
            productWeight: item.productWeight,
            productWeightUnit: item.productWeightUnit,
          });
        }
      }

      // 配列をソート
      products.sort((a, b) => {
        if (a.productName > b.productName) return 1;
        if (a.productName < b.productName) return -1;
        return 0;
      });

      // 製品別に集計
      for (const product of products) {
        const filterItems = this.items.filter((e) => e.productName == product.productName);
        if (filterItems.length == 0) continue;
        results.push({
          productName: product.productName,
          productWeight: product.productWeight,
          productWeightUnit: product.productWeightUnit,
          checkResults: filterItems,
        });
      }

      return results;
    },
  },
};
</script>

<style scoped>
::v-deep .v-tabs-bar {
  height: 38px;
  background-color: transparent !important;
  border-bottom: solid 1px #0000001f !important;
}

::v-deep .v-tabs-items {
  background-color: transparent !important;
}

::v-deep .v-window {
  overflow: initial;
}

::v-deep .v-data-table .v-data-table__wrapper table .v-data-table-header tr th {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}

.v-data-table ::v-deep .v-data-table__wrapper table .v-data-table-header-mobile {
  display: none;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
  white-space: nowrap;
}
</style>
