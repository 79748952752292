<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="text-subtitle-1 text-sm-h6">製品一覧</span>
      </v-col>
      <v-col class="text-end" align-self="center">
        <v-btn
          class="white"
          color="primary"
          icon
          elevation="2"
          :large="$vuetify.breakpoint.smAndUp"
          @click.stop="$refs.editDialog.openDialog()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            :loading="loading"
            sort-by="productName"
            loading-text="読込中"
            no-data-text="データがありません"
            fixed-header
            hide-default-footer
            :disable-sort="$vuetify.breakpoint.xs"
            :height="getTableHeight"
            dense
          >
            <template v-slot:[`item.productWeight`]="{ item }">
              <span>{{ item.productWeight }}{{ item.productWeightUnit }}</span>
            </template>
            <template v-slot:[`item.weightRange`]="{ item }">
              <span>{{ item.weightMin }} ～ {{ item.weightMax }}{{ item.weightUnit }}</span>
            </template>
            <template v-slot:[`item.flowRange`]="{ item }">
              <span>{{ item.flowMin }} ～ {{ item.flowMax }}{{ item.flowUnit }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn icon @click.stop="$refs.editDialog.openDialog(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click.stop="$refs.deleteDialog.openDialog(item, item.productName)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:top>
              <DialogProductEdit ref="editDialog" :products="items" :storeItem="storeItem" />
              <DialogDocDelete ref="deleteDialog" target="製品" :removeItem="removeItem" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import dbProcess from "cumin-common/src/mixins/dbProcess";
import DialogProductEdit from "../organisms/DialogProductEdit.vue";
import DialogDocDelete from "../organisms/DialogDocDelete.vue";

export default {
  components: {
    DialogProductEdit,
    DialogDocDelete,
  },
  mixins: [dbProcess],
  data: () => ({
    loading: false,
    items: [],
    headers: [
      { text: "製品名", value: "productName", width: "40%" },
      { text: "製品重量", value: "productWeight", width: "20%" },
      { text: "重量範囲", value: "weightRange", width: "20%" },
      { text: "流量範囲", value: "flowRange", width: "20%" },
      { text: "操作", align: "center", sortable: false, value: "action", width: 96 },
    ],
    message: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    this.loadList();
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 194 : 188);
      return height <= 500 ? 500 : height;
    },
  },
  methods: {
    /**
     * DBから機械一覧を取得
     */
    async loadList() {
      this.loading = true;

      const shop = this.$store.getters.getShop;
      this.items = await this.getQueryDoc({
        collection: "weightContorolProducts",
        where: [{ fieldPath: "shopUID", opStr: "==", value: shop.shopUID }],
      });

      this.loading = false;
    },

    /**
     * 記入情報をDBに登録
     * @param {boolean} isCreate 新規作成かどうか
     * @param {string} docId uid
     * @param {object} data 登録情報
     * @return {number} 成功したか
     */
    async storeItem({ isCreate, docId, data }) {
      // オフライン時の処理
      if (!navigator.onLine) {
        this.$refs.sendErrorDialog.open("offline");
        return false;
      }

      const result = await this.writeTransaction([
        { method: isCreate ? "set" : "update", collection: "weightContorolProducts", docId, data },
      ]);

      // 送信失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        return false;
      }

      this.loadList();
      this.message = isCreate ? "製品を登録しました。" : "製品情報を更新しました。";
      this.messageDialog = true;
      return true;
    },

    /**
     * 選択した製品をDBから削除
     * @param {string} docId
     */
    async removeItem(docId) {
      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      const result = await this.writeTransaction([
        { method: "delete", collection: "weightContorolProducts", docId },
      ]);

      // 送信失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        return;
      }

      this.loadList();
      this.message = "製品を削除しました。";
      this.messageDialog = true;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper table thead tr th {
  height: 48px !important;
  background-color: #f8f8f8 !important;
  padding: 0 8px;
}

::v-deep .v-data-table:not(.v-data-table--mobile) .v-data-table__wrapper table tbody tr td {
  height: 42px !important;
  padding: 0 8px !important;
}

::v-deep .v-data-table .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
}
</style>
