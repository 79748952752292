<template>
  <div>
    <div class="px-4 pb-4 text-caption text-sm-body-2">
      <div class="d-flex">
        <div>
          <p class="d-flex">
            <span class="text-no-wrap">製品名：</span>
            <span>{{ result.productName }}</span>
          </p>
          <p class="d-flex">
            <span class="text-no-wrap">重量：</span>
            <span>{{ result.productWeight }}{{ result.productWeightUnit }}</span>
          </p>
          <p class="d-flex">
            <span class="text-no-wrap">適正範囲：</span>
            <span>{{ result.min }} ～ {{ result.max }}{{ result.unit }}</span>
          </p>
          <p>
            <span>点検結果：</span>
            <span :class="result.isNormalForReport ? '' : 'red--text font-weight-bold'">{{
              result.isNormalForReport ? "OK" : "NG"
            }}</span>
          </p>
        </div>
        <v-spacer></v-spacer>
        <slot />
      </div>
      <p class="d-flex">
        <span class="text-no-wrap">送信日時：</span>
        <span>{{ formatDate(result.sentAt, "YYYY/MM/DD HH:mm") }}（{{ result.sender }}）</span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">確認日時：</span>
        <span>
          <span>{{ formatDate(result.confirmedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.confirmerName">（{{ result.confirmerName }}）</span>
        </span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">承認日時：</span>
        <span>
          <span>{{ formatDate(result.approvedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.approverName">（{{ result.approverName }}）</span>
        </span>
      </p>
    </div>

    <p class="mb-1 pl-4 text-caption text-sm-body-2">詳細結果</p>
    <v-card class="pa-2 mb-4" outlined>
      <v-data-table
        class="transparent"
        :headers="headers"
        :items="result.checkItems"
        :items-per-page="-1"
        sort-by="machineName"
        fixed-header
        hide-default-footer
        dense
      >
        <template #[`header.flowMin`]="{ header }">
          <span>{{ header.text }}({{ result.unit }})</span>
        </template>
        <template #[`header.flowMax`]="{ header }">
          <span>{{ header.text }}({{ result.unit }})</span>
        </template>
        <template #[`item.machineName`]="{ item }">
          <div style="min-width: 100px">{{ item.machineName }}</div>
        </template>
        <template #[`item.weightResult[${i}]`]="{ item }" v-for="i in 5">
          <span
            :key="i"
            :class="
              item.weightResults[i - 1] < result.min || item.weightResults[i - 1] > result.max
                ? ngClass
                : ''
            "
          >
            {{ item.weightResults[i - 1] ? item.weightResults[i - 1].toFixed(1) : "" }}
          </span>
        </template>
        <template v-slot:[`item.flowMin`]="{ item }">
          <span :class="item.flowMin < result.min || result.max < item.flowMin ? ngClass : ''">
            {{ item.flowMin }}
          </span>
        </template>
        <template v-slot:[`item.flowMax`]="{ item }">
          <span :class="item.flowMax < result.min || result.max < item.flowMax ? ngClass : ''">
            {{ item.flowMax }}
          </span>
        </template>
        <template v-slot:[`item.flowResult`]="{ item }">
          <span :class="item.flowResult == 'NG' ? ngClass : ''">
            {{ item.flowResult }}
          </span>
        </template>
        <template v-slot:[`item.totalResult`]="{ item }">
          <span
            :class="
              item.totalResult == 'NG' ? ngClass : item.totalResult == '未点検' ? 'grey--text' : ''
            "
          >
            {{ item.totalResult }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";

export default {
  props: {
    result: Object,
  },
  mixins: [calcDate],
  data: () => ({
    ngClass: "red--text font-weight-bold",
  }),
  computed: {
    /**
     * 詳細結果テーブルのヘッダー情報
     * @return {array}
     */
    headers() {
      const headers = [
        { text: "機械", value: "machineName" },
        { text: "点検結果", value: "totalResult", sortable: false, align: "center", width: 100 },
      ];
      const insertHeaders = [];

      if (this.result.type == "重量") {
        const colMax = this.result.checkItems.reduce(
          (max, current) => Math.max(max, current.weightResults.length),
          1
        );

        for (let i = 1; i <= colMax; i++) {
          insertHeaders.push({
            text: `${i}回目`,
            value: `weightResult[${i}]`,
            align: "end",
            width: 66,
          });
        }
      }

      if (this.result.type == "流量") {
        insertHeaders.push(
          ...[
            { text: "下限", value: "flowMin", align: "end", width: 80 },
            { text: "上限", value: "flowMax", align: "end", width: 80 },
            { text: "異常なし", value: "flowResult", sortable: false, align: "center", width: 100 },
          ]
        );
      }

      Array.prototype.splice.apply(headers, [1, 0].concat(insertHeaders));
      return headers;
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 42px !important;
}
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

.v-data-table ::v-deep .v-data-table__wrapper table .v-data-table-header-mobile {
  display: none;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
  white-space: nowrap;
}
</style>
